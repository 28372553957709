import { Link, createFileRoute, redirect, useNavigate } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { IoChevronBack } from 'react-icons/io5';
import { IoAddCircleOutline } from 'react-icons/io5';

import { IoIosRemoveCircle } from 'react-icons/io';
import { IoIosAddCircle } from 'react-icons/io';
import { useCartStore } from '../store/cartStore';
import { CustomisationType, DiningSession } from '../Interface';
import { useAuthStore } from '../store/authStore';
import clsx from 'clsx';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
  convertToAMPMFormat,
  getCustomisation,
  isAuthenticated,
  parseAxiosError,
} from '../utils/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { OrderStatus, OrderedDishStatus, ReactQueryEnum, SessionStatus } from '../constants/enum';
import useAxios from '../hooks/useAxios';
import Button from '../components/Button';
import no_order from '../assets/animations/no_order.json';
import orderReviewLoading from '../assets/animations/orderReviewLoading.json';
import Lottie from 'lottie-react';
import { AxiosError } from 'axios';
import SessionExpiredError from '../errors/SessionExpiredError';
import CollapsibleCard from '../components/UI/CollapsibleCard';
import Badge, { BadgeType } from '../components/UI/Badge';
import { MdOutlineRemoveCircleOutline } from 'react-icons/md';
import CustomModal from '../components/Modal';
import Loading from '../components/Loading';
import { PiCallBellLight } from 'react-icons/pi';
import CustomError from '../components/Error';
import OrderReviewDish from '../components/OrderReviewDish';
import { HotToast } from '../components/HotToast';
import { GoTrash } from 'react-icons/go';
export const Route = createFileRoute('/orderReview')({
  beforeLoad: () => {
    if (!isAuthenticated()) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  component: OrderReview,
});

function OrderReview() {
  const [confirmRequestBill, setConfirmRequestBill] = useState<boolean>(false);
  const [confirmPlaceOrder, setConfirmPlaceOrder] = useState<boolean>(false);
  const [confirmClearCart, setConfirmClearCart] = useState<boolean>(false);

  const [cookingInstruction, setCookingInstruction] = useState<string>('');
  const [showCookingInstructions, setShowCookingInstructions] = useState<boolean>(false);
  const { getWithAuth, patchWithAuth, postWithAuth } = useAxios();
  const queryClient = useQueryClient();
  const { cart, cartLoading, clearCart } = useCartStore((state) => ({
    cart: state.cart,
    cartLoading: state.cartLoading,
    clearCart: state.clearCart,
  }));

  const { userName, tablePIN, sessionID, clearTokens } = useAuthStore((state) => ({
    userName: state.userName,
    tablePIN: state.tablePIN,
    sessionID: state.sessionID,
    clearTokens: state.clearTokens,
  }));

  const {
    data: sessionData,
    isError: isSessionError,
    isLoading: isSessionLoading,
    failureReason,
  } = useQuery<DiningSession>({
    queryKey: [ReactQueryEnum.SESSION, sessionID],
    queryFn: async () => {
      const response = await getWithAuth(`/app/dining-session/session`);
      return response;
    },
    retry(failureCount, error) {
      if ((error as AxiosError).response?.status === 404) {
        return false;
      }
      if (error instanceof SessionExpiredError) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval: 10000,
  });

  const { isPending: helpPending, mutate: helpMutate } = useMutation({
    mutationFn: async () => {
      const response = await postWithAuth('/app/dining-session/help', {});
      return response;
    },
    onSuccess: () => {
      HotToast({ message: 'called for assistance!', type: 'success' });
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      HotToast({ message: errorMessage, type: 'error' });
    },
  });

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await patchWithAuth(`/app/bill/request-bill`, {});
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      HotToast({ message: errorMessage, type: 'error' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.SESSION, sessionID] });
      HotToast({ message: 'Bill requested successfully', type: 'success' });
    },
  });

  const [loading, setLoading] = useState(false);

  const { isPending: placeOrderIsPending, mutate: placeOrderMutate } = useMutation({
    mutationFn: async () => {
      setLoading(true);
      const response = await postWithAuth(`/app/dining-session/place-order`, {
        cookingInstructions: cookingInstruction,
      });
      return response;
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      HotToast({ message: errorMessage, type: 'error' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.SESSION, sessionID] });
      HotToast({ message: 'Order placed successfully', type: 'success' });
    },
    onSettled: () => {
      setLoading(false);
      setCookingInstruction('');
    },
  });

  useEffect(() => {
    if (cart.length === 0) {
      queryClient.invalidateQueries({
        queryKey: [ReactQueryEnum.SESSION, sessionID],
      });
    }
  }, [cart]);

  if (sessionData?.status === SessionStatus.CHECKOUT) {
    // if (true) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center">
        <p className="text-2xl">Checkout in progress...</p>
        <Lottie className="w-auto h-[25vh]" animationData={orderReviewLoading} loop={true} />
        <Link to={`/bill/${sessionID}`} className="text-primary underline text-lg">
          View bill
        </Link>
      </div>
    );
  }

  if (sessionData?.status === SessionStatus.OPEN) {
    return (
      <div className="w-full h-screen flex flex-col justify-center items-center">
        <p className="font-medium">Your session has ended!</p>
        <Link to="/" className="text-primary underline">
          Go to Home
        </Link>
      </div>
    );
  }

  if (isSessionLoading) return <Loading />;

  if (isSessionError) {
    const error = failureReason as AxiosError;
    const { errorMessage } = parseAxiosError(error);
    if (error instanceof SessionExpiredError) {
      clearTokens();
      return (
        <CustomError>
          <p>Your session has ended!</p>
          <Link to="/" className="text-primary underline">
            Go to Home
          </Link>
        </CustomError>
      );
    } else {
      return (
        <CustomError>
          <p className="font-medium ">{errorMessage}</p>
          <p className="text-sm font-medium">Please try again later</p>
        </CustomError>
      );
    }
  }

  function getOrderStatusext(status: OrderStatus) {
    switch (status) {
      case OrderStatus.PENDING:
        return 'Awaiting Confirmation';
      case OrderStatus.CONFIRMED:
        return 'Order Confirmed';
      case OrderStatus.CANCELLED:
        return 'Order Cancelled';
      case OrderStatus.REJECTED:
        return 'Order Rejected';
      case OrderStatus.SERVED:
        return 'Order Served';
      default:
        return 'Order Porcessing';
    }
  }

  return (
    <div
      className={clsx('relative ', {
        'pointer-events-none opacity-50': cartLoading,
      })}
    >
      <div className="rounded-b-2xl pb-4 sticky -top-1 left-0 bg-gradient-to-b from-gradient_black to-gradient_mid z-[100] pt-4">
        <div className="mx-auto w-[85%] flex justify-between items-center">
          <Link to="/order" className="">
            <IoChevronBack className="rounded-full text-white bg-white bg-opacity-50 text-2xl  p-1" />
          </Link>
          <motion.button
            onClick={() => {
              helpMutate();
            }}
            whileTap={{ scale: 0.97 }}
            className="px-2 p-1 rounded-md border border-gray-300 flex gap-2 justify-center items-center"
            disabled={helpPending}
          >
            <p className="text-sm text-gray-300">Call for help</p>
            <PiCallBellLight className="h-5 w-5 text-gray-300" />
          </motion.button>
        </div>
      </div>
      {cart.length > 0 && (
        <div className="mt-4 w-[85%] h-fit mx-auto rounded-2xl bg-white relative  shadow-orderHistory px-3 py-4">
          <div className="w-full max-h-[45vh] overflow-y-auto order mt-2">
            {cart.map((item, index) => (
              <OrderReviewDish
                key={index}
                item={item}
                index={index}
                cartLength={cart.length}
                userName={userName as string}
              />
            ))}
          </div>
          {!showCookingInstructions && (
            <motion.div
              whileTap={{ scale: 0.97 }}
              className="w-full flex justify-between items-center py-2 pl-1"
              onClick={() => {
                setShowCookingInstructions(true);
              }}
            >
              <motion.p className="text-grey_text underline ">Add cooking instructions</motion.p>
              <motion.button className="" whileTap={{ scale: 0.97 }} onClick={() => {}}>
                <motion.div transition={{ duration: 0.2 }}>
                  <IoAddCircleOutline className="text-grey_text " />
                </motion.div>
              </motion.button>
            </motion.div>
          )}
          {showCookingInstructions && (
            <motion.div className="border rounded-lg p-1 flex justify-center items-center mt-2">
              <textarea
                autoFocus
                onScroll={(e) => {
                  if (cookingInstruction.length === 0) {
                    setShowCookingInstructions(false);
                  }
                }}
                onBlur={(e) => {
                  if (cookingInstruction.length === 0) {
                    setShowCookingInstructions(false);
                  }
                }}
                placeholder="Add cooking instructions"
                className="w-full h-full resize-none focus:outline-none"
                value={cookingInstruction}
                onChange={(e) => {
                  setCookingInstruction(e.target.value);
                }}
                name=""
                rows={3}
                id=""
              ></textarea>
            </motion.div>
          )}
          <div className="flex justify-end w-full mt-4">
            <CustomModal
              isOpen={confirmPlaceOrder}
              onClose={() => {
                setConfirmPlaceOrder(false);
              }}
            >
              <div className="mt-3">
                <p className="text-justify">
                  {sessionData?.billRequested
                    ? 'Bill already requested. Placing a new order will cancel it. Do you want to place the order?'
                    : 'Do you want to place the order?'}
                </p>
                <div className="flex gap-2 justify-end items-center mt-4">
                  <button
                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                    onClick={() => {
                      setConfirmPlaceOrder(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="inline-flex items-center gap-2 rounded-md bg-primary py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-primary-dark data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-primary"
                    onClick={() => {
                      if (!sessionID) {
                        console.error('Session ID not found');
                        return;
                      }
                      // placeOrder(cookingInstruction);
                      setConfirmPlaceOrder(false);
                      placeOrderMutate();
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </CustomModal>
            <CustomModal
              isOpen={confirmClearCart}
              onClose={() => {
                setConfirmClearCart(false);
              }}
            >
              <div className="">
                <p>Are you sure you want to clear the cart?</p>
                <div className="flex gap-2 justify-end items-center mt-4">
                  <button
                    className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                    onClick={() => {
                      setConfirmClearCart(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="inline-flex items-center gap-2 rounded-md bg-primary py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-red-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-red-500"
                    onClick={() => {
                      clearCart();
                      setConfirmClearCart(false);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </CustomModal>

            <div className="flex gap-4 flex-1 items-center">
              <Button
                title="Place Order"
                type="primary"
                isLoading={loading}
                className="max-w-full"
                onClick={() => {
                  if (!sessionID) {
                    console.error('Session ID not found');
                    return;
                  }
                  // placeOrder(cookingInstruction);
                  setConfirmPlaceOrder(true);
                }}
              />
              <button
                className="rounded-lg p-2 bg-gray-100 text-gray-800"
                onClick={() => {
                  setConfirmClearCart(true);
                }}
              >
                <GoTrash className="h-6 w-6 " />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={clsx('w-full relative  mx-auto mt-8')}>
        <p className="text-xl font-semibold  w-[85%]  mx-auto">
          Order <span className="text-primary"> History</span>
        </p>
        <div className="max-h-[72vh] overflow-y-auto order flex pt-3 flex-col justify-start items-center gap-4 no-scrollbar pb-5">
          {sessionData &&
            (sessionData.confirmedOrders.length > 0 || sessionData.pendingOrders.length > 0 ? (
              [...sessionData.confirmedOrders, ...sessionData.pendingOrders]
                .sort((order1, order2) => order2.orderId - order1.orderId)
                .map((order) => (
                  <CollapsibleCard className="w-[85%]" key={order.orderId}>
                    <div className=" flex flex-col justify-center items-start w-full">
                      <p>Order: #{order.orderId}</p>
                      <div className="flex gap-2 justify-start items-center">
                        <p className="text-grey_text text-xs">{convertToAMPMFormat(order.timeOrdered)}</p>
                        <p
                          className={clsx(' text-sm', {
                            'text-primary': order.orderStatus === OrderStatus.REJECTED,
                            'text-yellow-500': order.orderStatus === OrderStatus.PENDING,
                            'text-green-500':
                              order.orderStatus === OrderStatus.COMPLETED ||
                              order.orderStatus === OrderStatus.CONFIRMED ||
                              order.orderStatus === OrderStatus.SERVED,
                          })}
                        >
                          {getOrderStatusext(order.orderStatus)}
                        </p>
                      </div>
                    </div>
                    <div className="w-full">
                      {order.dishes.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={clsx('grid grid-cols-[3fr_0.5fr_1.5fr] gap-2 place-items-stretch  pb-3   ', {
                              'mb-3 border-b': index !== order.dishes.length - 1,
                            })}
                          >
                            <div className="flex flex-col justify-center items-start w-full overflow-hidden text-ellipsis">
                              <p className="text-xs text-gold_start  text-ellipsis overflow-hidden ">
                                {capitalizeFirstLetter(item.userName)}
                              </p>
                              <p className="font-medium text-ellipsis overflow-hidden  ">
                                {capitalizeFirstLetterOfEachWord(item.name)}
                              </p>
                              {item.customisations && (
                                <p className="text-xs text-grey_text">{getCustomisation(item.customisations)}</p>
                              )}
                              <p className="text-xs text-grey_text">&#8377;{item.totalPrice}</p>
                            </div>
                            <div className="font-medium mt-4">x {item.quantity}</div>
                            <div className="flex flex-col justify-center items-end">
                              <p className="text-xs  text-grey_text text-right">
                                {convertToAMPMFormat(item.timeOrdered)}
                              </p>
                              <p
                                className={clsx('', {
                                  'text-primary': item.status === OrderedDishStatus.REJECTED,
                                  'text-yellow-500': item.status === OrderedDishStatus.PENDING,
                                  'text-green-500':
                                    item.status === OrderedDishStatus.SERVED ||
                                    item.status === OrderedDishStatus.CONFIRMED,
                                  'text-orange-400': item.status === OrderedDishStatus.PREPARING,
                                })}
                              >
                                {item.status}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CollapsibleCard>
                ))
            ) : (
              <div className="flex flex-col justify-center items-center gap-2">
                <Lottie className="w-auto h-[25vh]" animationData={no_order} loop={true} />
                <p className="text-sm text-grey_text">Oops, No order till Now!</p>
              </div>
            ))}
        </div>
      </div>
      <div className="w-full bg-white  fixed bottom-0 flex justify-center items-center py-2 border-0 px-5">
        <CustomModal
          isOpen={confirmRequestBill}
          onClose={() => {
            setConfirmRequestBill(false);
          }}
        >
          <div className="">
            <p>
              {sessionData?.billRequested
                ? 'Bill already requested. Do you want to request again?'
                : 'Do you want to request bill?'}
            </p>
            <div className="flex gap-2 justify-end items-center mt-4">
              <button
                className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                onClick={() => {
                  setConfirmRequestBill(false);
                }}
              >
                Cancel
              </button>
              <button
                className="inline-flex items-center gap-2 rounded-md bg-primary py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-primary-dark data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-primary"
                onClick={() => {
                  if (!sessionID) {
                    console.error('Session ID not found'); //error modal
                    return;
                  }
                  mutate();
                  setConfirmRequestBill(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </CustomModal>
        <Button
          disabled={sessionData?.billRequested}
          className="  text-xl"
          title={sessionData?.billRequested ? 'Bill Requested' : 'Request Bill'}
          type="primary"
          isLoading={isPending}
          onClick={() => {
            if (!sessionID) {
              console.error('Session ID not found'); //error modal
              return;
            }
            setConfirmRequestBill(true);
          }}
        />
      </div>
    </div>
  );
}
