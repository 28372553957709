import { Link, createFileRoute } from '@tanstack/react-router';
import clsx from 'clsx';
import { useState, useRef, useEffect } from 'react';
import { IoChevronBack } from 'react-icons/io5';
import { IoMdTime } from 'react-icons/io';
import { TbAirConditioning } from 'react-icons/tb';
import { IoWifiOutline } from 'react-icons/io5';
import { MdFoodBank } from 'react-icons/md';
import { LuParkingCircle } from 'react-icons/lu';
import StarRating from '../components/StarRating';
import Switch from '../components/Switch';
import StaticSearch from '../components/StaticSearch';
import FoodOptions from '../components/FoodOptions';
import Dish from '../components/Dish';
import { ReactQueryEnum } from '../constants/enum';
import useAxios from '../hooks/useAxios';
import { useQuery } from '@tanstack/react-query';
import { capitalizeFirstLetter } from '../utils/common';
import { DishInterface, FoodType, RestaurantCategoryInterface } from '../Interface';
import SkeletonDish from '../components/Skeleton/SkeletonDish';
import { useIntersection } from '@mantine/hooks';
import Loading from '../components/Loading';
import CustomError from '../components/Error';
import { useAuthStore } from '../store/authStore';

export const Route = createFileRoute('/restaurants/$restaurantId')({
  component: Restaurant,
});

function Restaurant() {
  let { restaurantId } = Route.useParams();
  restaurantId = restaurantId.split('-').pop() ?? '';
  const { getWithoutAuth } = useAxios();
  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const { getTableId, isAuthenticated, getRestaurantId } = useAuthStore((state) => ({
    getTableId: state.getTableID,
    isAuthenticated: state.isAuthenticated,
    getRestaurantId: state.getRestaurantId,
  }));

  const restaurantResult = useQuery({
    queryKey: [ReactQueryEnum.RESTAURANT, restaurantId],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/restaurant/${restaurantId}`);
      return response;
    },
  });

  const menuResult = useQuery({
    queryKey: [ReactQueryEnum.MENU, restaurantId],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/dish/menu/${restaurantId}`);
      return response;
    },
  });

  const handleScrollToCategory = (categoryId: string) => {
    if (categoryRefs.current[categoryId]) {
      categoryRefs.current[categoryId]!.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 1,
  });
  const logoContainerRef = useRef<HTMLDivElement>(null);
  const { ref: logoRef, entry: logoEntry } = useIntersection({
    root: containerRef.current,
    threshold: 1,
  });

  const [isVegOnly, setIsVegOnly] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  if (restaurantResult.isLoading) return <Loading />;
  if (restaurantResult.isError)
    return (
      <CustomError>
        <p className="font-medium ">Unexpected Error Occured</p>
        <p className="text-sm font-medium">Please try again later</p>
      </CustomError>
    );

  return (
    <div
      className=" w-full h-screen no-scrollbar "
      style={{
        overflowY: logoEntry?.isIntersecting ? 'scroll' : 'hidden',
      }}
      ref={containerRef}
    >
      <img
        src={'/images/restaurant/restaurant2.svg'}
        className="fixed top-0 left-0 -z-10 object-cover w-full h-1/2"
        alt=""
      />
      <Link className=" mt-3 ml-3 fixed" to="/">
        <IoChevronBack className="rounded-full bg-white text-3xl  p-1" />
      </Link>

      <div
        ref={ref}
        className="p-4 transition-all duration-300 ease-linear relative top-1/3  w-full h-[92vh]   bg-white   no-scrollbar  flex flex-col justify-center items-center"
        style={{
          borderTopLeftRadius: entry?.isIntersecting ? '1rem' : '4rem',
          borderTopRightRadius: entry?.isIntersecting ? '1rem' : '4rem',
        }}
      >
        <div
          ref={logoContainerRef}
          className={clsx(
            '  transition-all duration-300 ease-linear h-full w-full bg-white no-scrollbar  flex flex-col justify-start items-center px-2',
            {
              'overflow-y-auto': entry?.isIntersecting,
              'overflow-hidden': !entry?.isIntersecting,
            },
          )}
          style={{
            borderTopLeftRadius: entry?.isIntersecting ? '1rem' : '4rem',
            borderTopRightRadius: entry?.isIntersecting ? '1rem' : '4rem',
          }}
        >
          <img
            ref={logoRef}
            src="/images/restaurant/haribhavanam/logo.jpg"
            alt=""
            className="h-20 rounded-full shadow-lg object-contain mt-4 "
          />
          <p className="font-semibold text-3xl my-3 text-center">{capitalizeFirstLetter(restaurantResult.data.name)}</p>
          <StarRating rating={restaurantResult.data.ratings ?? 1} totalStars={5} totalRatings={69} />
          <p className="">{restaurantResult.data.cuisine.join(', ')}</p>
          <div className="grid w-full grid-cols-2 gap-1 mt-3">
            <p className="justify-self-start">{restaurantResult.data.address.street}</p>
            <p className="justify-self-end">&#8377; {restaurantResult.data.costForTwo}</p>
            {!restaurantResult.data.pureVeg ? (
              <div className="flex gap-2 justify-center items-center justify-self-start">
                <p>Veg Only</p>
                <Switch isOn={isVegOnly} onClick={() => setIsVegOnly(!isVegOnly)} />
              </div>
            ) : (
              <div className=""></div>
            )}
            <div className="flex gap-1 justify-center items-center justify-self-end">
              <IoMdTime className="text-xl" />
              <p>20 -25 mins</p>
            </div>
          </div>
          <hr className="w-full h-1 mt-4 mb-3" />
          <div className="grid w-full grid-cols-2 gap-1">
            {restaurantResult.data.acAvailable && (
              <div className="flex gap-2 justify-start items-center">
                <TbAirConditioning />
                <p>Air Conditioning</p>
              </div>
            )}
            {restaurantResult.data.wifiAvailable && (
              <div className="flex gap-2 justify-start items-center">
                <IoWifiOutline />
                <p>Free Wifi</p>
              </div>
            )}
            {restaurantResult.data.acAvailable && (
              <div className="flex gap-2 justify-start items-center">
                <MdFoodBank />
                <p>Dine In</p>
              </div>
            )}
            {restaurantResult.data.parkingAvailable && (
              <div className="flex gap-2 justify-start items-center">
                <LuParkingCircle />
                <p>Parking Available</p>
              </div>
            )}
          </div>
          <div className="sticky -top-[1px] bg-white w-full py-3">
            {!menuResult.isLoading && (
              <div className="flex w-full justify-between items-center gap-3  ">
                <StaticSearch search={search} setSearch={setSearch} />
                <FoodOptions menu={menuResult.data} onCategoryClick={handleScrollToCategory} />
              </div>
            )}
          </div>

          <div className="w-full mt-6">
            {/* <p className=" w-full text-left mb-3 text-xl font-medium">Explore</p> */}
            <div className="flex flex-col gap-5 mb-3">
              {menuResult.isLoading
                ? Array.from({ length: 5 }).map((_, index) => <SkeletonDish key={index} />)
                : menuResult.data !== undefined &&
                  menuResult.data.map((menu: RestaurantCategoryInterface) => (
                    <div
                      className=""
                      key={menu.restaurantCategoryId}
                      id={menu.restaurantCategoryId}
                      ref={(el) => (categoryRefs.current[menu.restaurantCategoryId] = el)}
                    >
                      <p className="text-left text-base font-semibold mb-2">
                        {capitalizeFirstLetter(menu.restaurantCategoryName)}
                      </p>
                      <div className="grid grid-cols-1 gap-2">
                        {menu.dishes.map((dish: DishInterface) => {
                          if (isVegOnly && dish.dishType === FoodType.VEG) {
                            return (
                              <Dish
                                dish={dish}
                                key={dish._id}
                                tableId={getTableId()}
                                isAuthenticated={isAuthenticated()}
                                differentRestarant={restaurantId !== getRestaurantId()}
                              />
                            );
                          } else if (!isVegOnly) {
                            return (
                              <Dish
                                dish={dish}
                                key={dish._id}
                                tableId={getTableId()}
                                isAuthenticated={isAuthenticated()}
                                differentRestarant={restaurantId !== getRestaurantId()}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
