import { Link, createFileRoute } from '@tanstack/react-router';

import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryEnum } from '../../constants/enum';
import { useAuthStore } from '../../store/authStore';
import useAxios from '../../hooks/useAxios';
import { AxiosError } from 'axios';
import { IBill } from '../../Interface/Bill';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
  copyToClipboard,
  getCurrentDateTime,
  getCustomisation,
  parseAxiosError,
} from '../../utils/common';

import clsx from 'clsx';
import { GoShareAndroid } from 'react-icons/go';

import Share from '../../components/Share';
import Loading from '../../components/Loading';
import CustomError from '../../components/Error';
import CustomDrawerV2 from '../../components/CustomDrawerV2';
import { Rating } from '@smastrom/react-rating';
import { feedbackOptions } from '../../constants/feedbackOptions';
import Button from '../../components/Button';
import { HotToast } from '../../components/HotToast';
import { VscFeedback } from 'react-icons/vsc';
import BillSplitCard from '../../components/BillSplitCard';

export const Route = createFileRoute('/bill/$sessionId')({
  component: Bill,
});

const Star = (
  <path d="M62 25.154H39.082L32 3l-7.082 22.154H2l18.541 13.693L13.459 61L32 47.309L50.541 61l-7.082-22.152L62 25.154z" />
); // Source: https://www.svgrepo.com/svg/353297/star

const customStyles = {
  itemShapes: Star,
  boxBorderWidth: 2,

  activeFillColor: ['#FEE2E2', '#FFEDD5', '#FEF9C3', '#ECFCCB', '#D1FAE5'],
  activeBoxColor: ['#da1600', '#db711a', '#dcb000', '#61bb00', '#009664'],
  activeBoxBorderColor: ['#c41400', '#d05e00', '#cca300', '#498d00', '#00724c'],

  inactiveFillColor: 'white',
  inactiveBoxColor: '#dddddd',
  inactiveBoxBorderColor: '#a8a8a8',
};
const ratingsToText = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];

function Bill() {
  const queryClient = useQueryClient();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { sessionId } = Route.useParams();
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);
  const { getWithoutAuth, postWithoutAuth } = useAxios();
  const [rating, setRating] = useState(-1);
  const [reviewText, setReviewText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(
    feedbackOptions.reduce(
      (acc, option) => {
        acc[option.id] = false;
        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );

  const shareRef = useRef<{ title: string; url: string }>({
    title: 'Hello there, We appreciate your use of Cibo. You can view the bill here.',
    url: `${import.meta.env.VITE_EMPRESS_HOST}/bill/${sessionId}`,
  });

  const { fingerPrint } = useAuthStore((state) => ({
    fingerPrint: state.fingerPrint,
  }));

  const {
    data: billData,
    isLoading: isBillLoading,
    isError: isBillError,
  } = useQuery<IBill>({
    queryKey: [ReactQueryEnum.BILL],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/bill/view-bill/${sessionId}`);
      return response;
    },
  });

  const { data: restaurantReviewData } = useQuery<{
    isReviewed: boolean;
  }>({
    queryKey: [ReactQueryEnum.RESTAURANT_REVIEW, sessionId],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/restaurant-feedback/session/${sessionId}/device/${fingerPrint}`);
      return response;
    },

    enabled: !!sessionId && !!fingerPrint,
  });

  const {
    isPending: isReviewPending,
    error: reviewError,
    mutate: reviewMutate,
  } = useMutation({
    mutationFn: async () => {
      const restaurantId = billData?.restaurantId;
      if (!restaurantId) {
        throw new Error('Restaurant not found');
      }
      const likedMost = Object.keys(selectedOptions).filter((key) => selectedOptions[key]);
      const data = {
        ...(rating !== -1 && { rating }),
        ...(reviewText !== '' && { description: reviewText }),
        ...(likedMost.length > 0 && { likedMost }),
        deviceId: fingerPrint,
        sessionId,
      };

      if (likedMost.length === 0 && rating === -1 && reviewText === '') {
        throw new Error('Please provide feedback');
      }
      const response = await postWithoutAuth(`/app/restaurant-feedback/${restaurantId}`, data);
      return response;
    },
    onSuccess: () => {
      HotToast({
        type: 'success',
        message: 'Thank you for your feedback',
      });
      queryClient.invalidateQueries({ queryKey: [ReactQueryEnum.RESTAURANT_REVIEW, sessionId, fingerPrint] });
      setDrawerOpen(false);
    },
    onError: (error: unknown) => {
      const message =
        error instanceof AxiosError
          ? parseAxiosError(error).errorMessage
          : error instanceof Error
            ? error.message
            : 'Unable to submit feedback at this time';
      HotToast({
        type: 'error',
        message,
      });
    },
  });

  useEffect(() => {
    if (restaurantReviewData && restaurantReviewData.isReviewed === false) {
      setTimeout(() => {
        setDrawerOpen(true);
      }, 3000);
    }
  }, [restaurantReviewData]);

  if (isBillLoading) {
    return <Loading />;
  }

  if (!billData || isBillError) {
    return (
      <CustomError>
        <p className="font-medium">Bill not found</p>
        <Link to="/" className="text-primary underline">
          Go to Home
        </Link>
      </CustomError>
    );
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareRef.current);
      } catch (error) {
        return;
      }
    } else {
      setShareDrawerOpen(true);
    }
  };

  const handleOptionChange = (optionId: string) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionId]: !prevOptions[optionId],
    }));
  };

  return (
    <div className="relative">
      <CustomDrawerV2 open={drawerOpen} setOpen={setDrawerOpen}>
        <div className="mt-4 relative">
          <p className="text-center font-medium">Rate your experince with {billData.restaurantName}</p>
          <div className="grid place-items-center mt-4">
            <Rating
              style={{ maxWidth: 250 }}
              value={rating}
              onChange={setRating}
              itemStyles={customStyles}
              radius="large"
              spaceBetween="small"
              spaceInside="large"
            />
            <p className="uppercase text-xl font-bold text-slate-600 mt-2">{ratingsToText[rating - 1]}</p>
          </div>

          <hr className="my-2" />
          <p className="text-center font-medium">What did the restaurant impress you with?</p>

          <div className="flex gap-2 justify-center items-center flex-wrap mt-2">
            {feedbackOptions.map((option) => (
              <label key={option.id} className="flex flex-col items-center">
                <input
                  type="checkbox"
                  className="hidden"
                  checked={selectedOptions[option.id]}
                  onChange={() => handleOptionChange(option.id)}
                />
                <img
                  src={option.image}
                  alt={option.label}
                  className={`w-24 rounded-full p-3 bg-slate-200 cursor-pointer ${
                    selectedOptions[option.id] ? 'border-4 border-green-600' : ''
                  }`}
                />
                <span className="mt-1">{option.label}</span>
              </label>
            ))}
          </div>
          <textarea
            autoFocus
            placeholder="Your review here"
            className="w-full h-full resize-none focus:outline-none p-2 rounded-md mt-3"
            value={reviewText}
            onChange={(e) => {
              setReviewText(e.target.value);
            }}
            name=""
            rows={3}
            id=""
          ></textarea>
          <p className=" underline text-sm mx-auto text-center cursor-pointer mb-12 mt-4">Don't ask again</p>

          <div className="w-full fixed bottom-2 left-0 mx-auto">
            <Button
              title="Submit"
              onClick={reviewMutate}
              type="primary"
              className={clsx('!w-[90%] max-w-[500px] rounded-md px-5 py-2 bg-primary text-white font-medium')}
            />
          </div>
        </div>
      </CustomDrawerV2>
      <Share
        url={shareRef.current.url}
        title={`Hi there, You can view the bill for ${billData.restaurantName} by clicking the link below.`}
        isOpen={shareDrawerOpen}
        setIsOpen={setShareDrawerOpen}
      />

      <div className=" bg-gradient-to-b to-primary from-gradient_black z-[100] pt-4 pb-4 rounded-b-xl">
        <img src="/images/cibo_logo_primary_gold.svg" className="w-[15%] max-w-[80px] mx-auto " alt="" />
      </div>
      <div className="w-[85%] mx-auto  flex flex-col justify-center items-center shadow-orderHistory rounded-2xl my-3 py-4 px-4">
        <div className="flex justify-center items-center w-full gap-2 mb-3">
          {billData.restaurantLogoUrl && (
            <img
              src={billData.restaurantLogoUrl}
              className="h-10  aspect-square shadow-orderHistory rounded-lg"
              alt=""
            />
          )}
          <p className="text-lg font-medium">{billData.restaurantName}</p>
        </div>
        <div className="flex justify-center items-center gap-1"></div>
        <div className="flex justify-between w-full">
          {/* <p className="text-sm">Order No. 22</p> */}
          <p className="text-sm">Table {billData.tableName}</p>
          <p className=" text-sm ">{getCurrentDateTime(billData.billCreationTime)}</p>
        </div>
        <div className="flex justify-between w-full">
          <p className=" text-sm ">{capitalizeFirstLetter(billData.captainName)}</p>
        </div>
      </div>
      <div className="w-[85%] h-fit mx-auto rounded-2xl bg-white relative  shadow-orderHistory px-1 py-3 my-3 ">
        <div className="grid grid-cols-[3fr_1fr_1fr_1fr] gap-2 place-items-strech text-grey_text mb-2 px-2">
          <p>Item</p>
          <p className="text-center">Qty</p>
          <p className="text-center">Price</p>
          <p className="text-right">Amt</p>
        </div>
        <div className="w-full h-fit max-h-[50vh] overflow-y-auto  mt-2 custom-scrollbar px-2">
          {billData.orders.map((order, index) => (
            <div
              key={index}
              className={clsx('grid grid-cols-[3fr_1fr_1fr_1fr] gap-2 place-items-strech  pb-3    ', {
                'border-b-0 ': index === billData.orders.length - 1,
                'mb-3 border-b': index !== billData.orders.length - 1,
              })}
            >
              <div className="flex flex-col justify-start items-start gap-0.5">
                {/* <div className="mt-2">
                  <FoodTypeIndicator size={1} type={order.type} />
                </div> */}
                <p className=" leading-[18px]">{capitalizeFirstLetterOfEachWord(order.name)} </p>
                {order.customisations && (
                  <p className="text-xs text-grey_text leading-none">{getCustomisation(order.customisations)}</p>
                )}
              </div>
              <div className="flex justify-center">
                <p className="">{order.quantity}</p>
                {/* <div className="flex border rounded-md px-3 py-1 justify-center items-center gap-2 h-fit w-fit "></div> */}
              </div>
              <div className="text-black_1  flex justify-center items-start">
                <p className="w-fit">&#8377;{order.totalPrice}</p>
              </div>
              <div className="text-black_1  flex justify-end items-start">
                <p className="w-fit">&#8377;{order.amount}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={clsx('w-[85%] mx-auto   relative  rounded-2xl bg-white   shadow-orderHistory px-3 py-3', {})}>
        {/* <p className=" font-medium">Bill Summary</p> */}
        <div className="grid grid-cols-[3fr_1fr]  gap-1">
          <p>Sub-total</p>
          <p className="text-black_1 justify-self-end">&#8377;{billData.subTotal}</p>
          {/* <p>Packing Charges</p>
          <p className="text-black_1 justify-self-end">&#8377;20</p> */}
          <p>Taxes</p>
          <p className="text-black_1 justify-self-end">&#8377;{billData.tax.totalTaxAmount.toFixed(2)}</p>
          <p>Round off</p>
          <p className="text-black_1 justify-self-end">
            + &#8377;{(Math.round(billData.tax.totalTaxAmount) - billData.tax.totalTaxAmount).toFixed(2)}
          </p>
        </div>
        <hr className="my-2" />
        <div className="flex  justify-between font-medium">
          <p>Grand Total</p>
          <p className="text-black_1 justify-self-end">&#8377;{billData.total}</p>
        </div>
      </div>

      {billData.split && (
        <div className="w-[85%] h-fit mx-auto rounded-2xl bg-white relative  shadow-orderHistory px-1 py-3 my-3 ">
          <span className="text-xl font-medium px-2">User Split</span>
          <span>(Without taxes)</span>
          <div className="w-full h-fit max-h-[50vh] overflow-y-auto custom-scrollbar px-2">
            {billData.split.map((user, index) => (
              <BillSplitCard key={index} userSplit={user} bottomBorder={index !== billData.split.length - 1} />
            ))}
          </div>
        </div>
      )}
      <div
        onClick={handleShare}
        className={clsx('w-[85%] mx-auto mt-3 flex justify-center items-center gap-1 cursor-pointer ', {})}
      >
        <p className="underline  text-center">Share bill</p>
        <GoShareAndroid />
      </div>
      {restaurantReviewData && !restaurantReviewData.isReviewed && (
        <div
          onClick={() => {
            setDrawerOpen(true);
          }}
          className="w-[85%] mx-auto mt-3 flex justify-center items-center gap-2 cursor-pointer pb-8"
        >
          <p className="underline text-center">We'd like to hear your feedback</p>
          <VscFeedback />
        </div>
      )}
    </div>
  );
}
